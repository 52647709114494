import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { usePaginate, useQuery, useSubject } from '../../lib/hooks';
import CandidateJobService from '../../lib/services/candidate.job.service';
import { ApplicantJobData, JobPostData } from '../../lib/types';
import styles from './Jobboard.module.scss';
import heroImgM from '../../../src/images/jb-hero-m.jpg';
import heroImg from '../../../src/images/jb-hero.jpg';
import { jobboardCount$, jobboardJobs$, jobboardPageSize } from './state';
import SVGIcon from '../../components/SVGIcon/SVGIcon';
import CandidateJobCard from '../../components/CandidateJobCard/CandidateJobCard';
import ApplyModal from '../../components/ApplyModal/ApplyModal';
import ApplySuccessModal from '../../components/ApplySuccessModal/ApplySuccessModal';
import JobFilters from '../../components/Filters/JobFilters/JobFilters';
import { loggedIn$, userData$ } from '../../lib/state';
import Logger from '../../lib/logger';
import { ROUTES } from '../../lib/app-routes';
import { useNavigate } from 'react-router-dom';

const Jobboard = (): JSX.Element => {
  const [{page, apply, applySuccess}, setSearchParams, searchParams] = useQuery();
  const jobsData = useSubject<JobPostData[] | ApplicantJobData[]>(jobboardJobs$);
  const jobsCount = useSubject<number>(jobboardCount$);
  const userData = useSubject(userData$);
  const loggedIn = useSubject(loggedIn$);
  const navigate = useNavigate();
  const [{ count, next, previous, results}, error] = usePaginate(CandidateJobService.getJobs, searchParams.toString() || `page=1&page_size=${jobboardPageSize}`);

  const onNext = () => {
    if (next) setSearchParams(next.split('?')[1]);
  }

  const onPrevious = () => {
    if (previous) setSearchParams(previous.split('?')[1]);
  }

  const getShowStart = (): number | string => {
    return !page || page == '1' ? '1' : (parseInt(page) - 1) * jobboardPageSize + 1;
  }

  const getShowEnd = (): number | string => {
    return !page || page == '1' ? jobsData.length : ((parseInt(page) - 1) * jobboardPageSize) + jobsData.length;
  }

  useEffect(() => {
    if (results) jobboardJobs$.next(results);
    jobboardCount$.next(count);
  }, [results, count]);

  useEffect(() => {
    if (loggedIn && userData?.requires_onboarding) {
      Logger.log('Redirecting user to on-boarding from Jobboard.');
      navigate(ROUTES.onboarding.path.replace(':step', 'complete-profile'));
    }
  }, [userData])

  return (
    <>
      <Helmet>
        <title>Job Snob: Job Board</title>
      </Helmet>
      <div className={`content-wrap ${styles['topContainer']}`}>
        <div className={`content ${styles.content}`}>

          <div className={styles.header}>
            <div className={styles.imgWrap}>
              <picture>
                <source media="(min-width: 768px)" srcSet={heroImg} />
                <img src={heroImgM} />
              </picture>
            </div>

            <div className={styles.msgWrap}>
              <div className='eyebrow-hl-wrap'>
                <p className='eyebrow'>Search Jobs</p>
                <h1><span>Your Dream Career</span><br />Starts Here</h1>
              </div>

              <p>
                Job Snob is the only online job board and recruiting service dedicated exclusively to Medical Aesthetics. 
                Browse below for the latest and greatest job openings.
              </p>

              <a href='#jobSearch' className='icon60 icon60--black'>
                <SVGIcon id='chevron' color='white' />
              </a>
            </div>
          </div>

          <div className={styles.searchWrap}>
            <a id='jobSearch' className={styles.jobSearch}></a>
            <JobFilters pageSize={jobboardPageSize} isJobboard={true}/>
          </div>

        </div>
      </div>

      <div className={`content-wrap ${styles['listContainer']}`}>
        <p className='eyebrow'>
          {
            jobsCount > 0 && jobsData && !error &&
            `SHOWING ${getShowStart()}-${getShowEnd()} OF ${jobsCount} JOBS`
          }
          {
            jobsCount === 0 && !error && 'No results'
          }
          {
            jobsCount === -1 && !error && '...loading'
          }
          {
            error && `ERROR! ${error.message}`
          }
        </p>

        <div className={`content ${styles.listWrap}`}>
          {
            jobsData &&
            jobsData.map((job) => 
              <CandidateJobCard 
                key={job.uuid as string + Math.random()} 
                type='job' 
                jobData={job}
                disabled={!!userData && userData.role !== 'candidate'}
              />
            )
          }

          <p className='eyebrow pages'>
            {
              count > 0 && page &&
              `PAGE ${page} OF ${Math.ceil(count / jobboardPageSize)}`
            }
          </p>

          {
            (next || previous) &&
            <div className={styles.btnWrap}>
              {
                previous &&
                <a href='#jobSearch' className='btn btn--grey-6 btn--border' onClick={onPrevious}>Previous</a>
              }
              {
                next &&
                <a href='#jobSearch' className='btn btn--grey-6 btn--border' onClick={onNext}>Next</a>
              }
            </div>
          }
        </div>
      </div>

      {
        apply &&
        <>
          <Helmet>
            <title>Job Snob: Job Board - Apply</title>
          </Helmet>
          <ApplyModal page='current' />
        </>
      }
      {
        applySuccess &&
        <>
          <Helmet>
            <title>Job Snob: Job Board - Application Sent</title>
          </Helmet>
          <ApplySuccessModal />
        </>
      }
    </>
  )
};

export default Jobboard;