import React, { useEffect, useState } from 'react';
import { JobPostData, LifeCycleValue } from '../../lib/types';
import JobDetail from '../JobDetail/JobDetail';
import styles from './EmployerJobCard.module.scss';

const EmployerJobCardDetails = (props: {type: 'job' | 'draft', jobData: JobPostData}): JSX.Element => {
  const [expiration, setExpiration] = useState<string>();

  useEffect(() => {
    if (props.jobData.life_cycle === 'active' && props.jobData.expiration_date) {
      const oneDay = 1000*60*60*24;
      const now = Date.now();
      const then = new Date(props.jobData.expiration_date).getTime();
      const days = Math.round((then - now)/oneDay);
      setExpiration(`expires in ${days} day${days === 1 ? '' : 's'}`);
    }
  }, [props.jobData])
  
  const getLFIcon = (lifecycle: LifeCycleValue): string => {
    let val = '';
    switch (lifecycle) {
      case 'active':
        val = 'checkmark';
        break;

      case 'review':
        val = 'timer';
        break;

      case 'expired':
        val = 'hourglass';
        break;

      case 'deactivated':
        val = 'error';
        break;

      default:
        break;
    }
    return val;
  }

  return (
    <div className={`job-card-details-wrap ${styles.detailsWrap}`}>
      {
        props.jobData.duration !== '' &&
        <JobDetail icon='building' text={props.jobData.duration} />
      }

      {
        props.jobData.city !== '' && props.jobData.state !== '' &&
        <JobDetail icon='map-pin' text={`${props.jobData.city}, ${props.jobData.state === '--' ? props.jobData.state_other : props.jobData.state}`} />
      }
      
      {
        expiration &&
        <JobDetail icon='clock' text={expiration} />
      }

      {
        props.jobData.is_exclusive && 
        <div data-desktop>
          <JobDetail icon='diamond' text='Job Snob Exclusive' />
        </div>
      }

      {
        props.type === 'job' &&
        <JobDetail 
          icon={getLFIcon(props.jobData.life_cycle)} 
          text={ props.jobData.life_cycle === 'review' ? 'pending approval' : props.jobData.life_cycle } 
          lifeCycle={props.jobData.life_cycle}
        />
      }

      {
        props.jobData.has_pending_modifications && 
        <JobDetail icon='timer' text='Changes Under Review' lifeCycle='review' />
      }

      {
        props.jobData.is_exclusive && 
        <div data-mobile>
          <JobDetail icon='diamond' text='Job Snob Exclusive' />
        </div>
      }

      
    </div>
  )
};

export default EmployerJobCardDetails;