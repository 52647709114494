import React, { useEffect, useRef, useState } from 'react';
import styles from './JobDisplay.module.scss';
import { ErrorResponse, JobModifiedKeys, JobPostData, UserRole } from '../../lib/types';
import FavButton from '../FavButton/FavButton';
import JobDetail from '../JobDetail/JobDetail';
import ShareMedia from '../ShareMedia/ShareMedia';
import SVGIcon from '../SVGIcon/SVGIcon';
import { useSubject } from '../../lib/hooks';
import { jobCategoryOptions$ } from '../../lib/state';
import OnboardingApplyRedirect from '../OnboadringApplyRedirect';
import ReactMarkdown from 'react-markdown';

interface JobDisplayProps {
  data: JobPostData | null | undefined;
  role?: UserRole;
  error?: Error | ErrorResponse;
  review?: boolean;
  changed?: JobModifiedKeys[];
  modified?: boolean;
  brief?: boolean;
}

const JobDisplay = (props: JobDisplayProps): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);
  const jobCategoryOptions = useSubject(jobCategoryOptions$);
  const [redirect, setRedirect] = useState(false);
  const [hide, setHide] = useState(props.brief || false);
  const [seeMore, setSeeMore] = useState<boolean>();

  const onFavClick = () => {
    if (!props.role) {
      setRedirect(true);
    }
  }

  const hideToggle = () => {
    setHide(!hide);
  }

  useEffect(() => {
    if (props.brief && ref.current) {
      setSeeMore((ref.current as HTMLDivElement).clientHeight < 1200 ? false : true);
      (ref.current as HTMLDivElement).dataset.measured = 'true';
    }
  }, [props.brief, ref])

  return (
    <div ref={ref} className={`content ${styles.content}`}>
      {
        redirect && props.data &&
        <OnboardingApplyRedirect jobData={props.data} />
      }
      {
        props.error &&
        <p className='input-error load-error'>{(props.error as ErrorResponse).response.detail || props.error.message}</p>
      }
      {
        !props.data && !props.error &&
        <div className='loading-inview'></div>
      }
      {
        props.data &&
        <>
          {
            props.review && props.modified !== undefined &&
            <p className={`eyebrow ${styles.modifiedHeader}`} data-modified={props.modified}>{props.modified ? 'Modified' : 'Current'}</p>
          }
            <div className={styles.header} data-changed={props.changed?.includes('title')}>
              <h1>
                {props.data.title}
                {
                  // Company and employer name is provided only if the requesting user
                  // is an admin or recruiter.
                  (props.data.company_name || props.data.employer_name) &&
                  <span className={styles.companyName}> - {props.data.company_name} ({props.data.employer_name})</span>
                }
              </h1>
              {
                !props.review && (props.role === 'candidate' || !props.role) && props.data?.life_cycle === 'active' &&
                <FavButton key={props.data.is_favorited ? 1 : 0}
                  jobID={props.data.uuid as string} 
                  isFavorited={props.data.is_favorited as boolean} 
                  onClick={onFavClick}
                />
              }
              {
                !props.review &&
                <div className={styles.share} data-desktop>
                  <ShareMedia />
                </div>
              }
            </div>

            <div className='job-card-details-wrap'>
              <div data-changed={props.changed?.includes('duration')}>
                <JobDetail icon='building' text={props.data.duration} />
              </div>
              
              <div data-changed={props.changed?.includes('city') || props.changed?.includes('state') || props.changed?.includes('state_other') }>
                <JobDetail icon='map-pin' text={`${props.data.city}, ${props.data.state_other || props.data.state}`} />
              </div>
              
              
              {
                props.data.is_exclusive &&
                <div data-changed={props.changed?.includes('is_exclusive')}>
                  <JobDetail icon='diamond' text='Job Snob Exclusive' />
                </div>
              }
            </div>

            <div className={styles.descWrap} data-changed={props.changed?.includes('description')}>
              <ReactMarkdown>{props.data.description}</ReactMarkdown>
            </div>
            
            {
              (!seeMore || !hide) &&
              <>
                <div className={styles.detailsWrap}>
                  <div className={styles.details} data-responsibilities data-changed={props.changed?.includes('key_responsibilities')}>
                    <h2 className='eyebrow'>Key Job Responsibilities</h2>
                    <ul>
                    {
                      props.data.key_responsibilities.map((val, i) => 
                        <li key={i}><SVGIcon id='check' color='salmon' />{val}</li>
                      )
                    }
                    </ul>
                  </div>

                  <div className={styles.details} data-qualifications data-changed={props.changed?.includes('qualifications')}>
                  <h2 className='eyebrow'>Applicant Qualifications</h2>
                  <ul>
                  {
                    props.data.qualifications.map((val, i) => 
                      <li key={i}><SVGIcon id='check' color='salmon' />{val}</li>
                    )
                  }
                  </ul>
                  </div>
                
                {
                  props.data.compensation && props.data.compensation !== '' &&
                  <div className={styles.details} data-compensation data-changed={props.changed?.includes('compensation')}>
                    <h2 className='eyebrow'>Compensation</h2>
                    <ul>
                      <li><SVGIcon id='check' color='salmon' />{props.data.compensation}</li>
                    </ul>
                  </div>
                }
                
                </div>

                {
                  props.review &&
                  <>
                    <h3 className='eyebrow' data-changed={props.changed?.includes('categories')}>
                      <span className='bold'>Categories:</span> {jobCategoryOptions?.filter((o) => props.data?.categories.includes(o.value)).map(o => o.label).join(', ')}
                    </h3>
                    <h3 className='eyebrow' data-changed={props.changed?.includes('category_other')}>
                      <span className='bold'>Category Other:</span> {props.data?.category_other}
                    </h3>
                    <h3 className='eyebrow' data-changed={props.changed?.includes('requires_recruiter')}>
                      <span className='bold'>Requires Recruiter:</span> {props.data?.requires_recruiter ? 'Yes' : 'No'}
                    </h3>
                    <h3 className='eyebrow' data-changed={props.changed?.includes('is_exclusive')}>
                      <span className='bold'>Job Snob Exclusive:</span> {props.data?.is_exclusive ? 'Yes' : 'No'}
                    </h3>
                    <h3 className='eyebrow' data-changed={props.changed?.includes('is_confidential')}>
                      <span className='bold'>Confidential:</span> {props.data?.is_confidential ? 'Yes' : 'No'}
                    </h3>
                    <h3 className='eyebrow' data-changed={props.changed?.includes('expiration_date')}>
                      <span className='bold'>Expiration Date:</span>
                      &nbsp;{props.data?.expiration_date ? new Date(props.data?.expiration_date).toLocaleDateString() : undefined}
                    </h3>
                  </>
                }
                
                {
                  !props.review &&
                  <div className={styles.share} data-mobile>
                    <ShareMedia />
                  </div>
                }
              </>
            }

            {
              seeMore &&
              <button className={`eyebrow ${styles.revealBtn}`} onClick={hideToggle}>
                {
                  hide ? 'See more...' 
                  : <><SVGIcon id='arrow' rotate={180}/> Show Less</>
                }
              </button>
            }
        </>
      }
    </div>
  )
};

export default JobDisplay;