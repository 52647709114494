import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, useParams } from 'react-router-dom';
import DraftJobPost from '../../../components/DraftJobPost/DraftJobPost';
import OnboardingHeader from '../../../components/OnboardingHeader/OnboardingHeader';
import { ROUTES } from '../../../lib/app-routes';
import { useQuery, useSubject } from '../../../lib/hooks';
import CandidateJobService from '../../../lib/services/candidate.job.service';
import { userData$ } from '../../../lib/state';
import { JobPostData, UserData } from '../../../lib/types';
import CompleteProfile from '../CompleteProfile/CompleteProfile';
import OnboardApply from './OnboardApply';
import OnboardApplySuccess from './OnboardApplySuccess';
import { OnboardingStep } from './types';

const Onboarding = (): JSX.Element => {
  const { step } = useParams() as { step: OnboardingStep };
  const userData = useSubject<UserData | null>(userData$);
  const [onboardingJob, setOnboadingJob] = useState<JobPostData>();
  const [{ applySuccess, uuid }, setQuery] = useQuery();


  useEffect(() => {
    if ( userData && userData.onboarding_redirect && !uuid) {
      setQuery(`uuid=${userData.onboarding_redirect}`);
    }
  }, [userData])


  useEffect(() => {
    if (uuid) {
      CandidateJobService.getJob(uuid).subscribe({
        next: (dta) => setOnboadingJob(dta)
      })
    }
  }, [uuid])
  
  return (
    <>
      {
        userData && step &&
        <>
          { 
            step === 'complete-profile' &&
            <CompleteProfile { ...{userData}} />
          }

          { 
            step === 'submit-job-post' && userData.role === 'employer' &&
            <>
              <Helmet>
                <title>Job Snob: Employer Onboarding - Submit Job Post</title>
              </Helmet>
              <div className={`content-wrap content-wrap--no-padding bg-account`}>
                <OnboardingHeader step={step} role={userData.role} />
                <DraftJobPost user={userData} mode='new' />
              </div>
            </>
          }

          {
            step === 'apply-to-job' && userData.role === 'candidate' && !applySuccess &&
            <>
              <Helmet>
                <title>Job Snob: Candidate Onboarding - Apply to Job</title>
              </Helmet>
              <div className={`content-wrap content-wrap--no-padding bg-account`}>
                <OnboardingHeader step={step} role={userData.role} />
                {
                  userData.onboarding_redirect && onboardingJob && 
                  <OnboardApply jobData={onboardingJob}/>
                }

                {
                  !userData.onboarding_redirect &&
                  <Navigate to={ROUTES.jobboard.path} />
                }
              </div>
            </>
          }

          {
            step === 'apply-to-job' && userData.role === 'candidate' && applySuccess &&
            <>
              <Helmet>
                <title>Job Snob: Candidate Onboarding - Successfully Applied</title>
              </Helmet>
              <OnboardApplySuccess />
            </>
          }
        </>
      }
    </>
  )
};

export default Onboarding;