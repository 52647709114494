import React from 'react';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './ShareMedia.module.scss';

const ShareMedia = (): JSX.Element => {
  
  return (
    <div className={styles.container}>
      <a className={styles.fbLink} href={`http://www.facebook.com/share.php?u=${location.href}`} target='_blank' rel='noreferrer'>
        <SVGIcon id={'facebook'} />
      </a>
      
      <a className={styles.inLink} href={`https://www.linkedin.com/sharing/share-offsite/?url=${location.href}`} target='_blank' rel='noreferrer'>
        <SVGIcon id='linkedin' />
      </a>

      <a className={styles.emailLink} href={`mailto:?subject=Job Snob%20Job&body=${location.href}`} target='_blank' rel='noreferrer'>
        <SVGIcon id='email' />
      </a>
    </div>
  )
};

export default ShareMedia;