import React, { FormEvent, useRef} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../lib/app-routes';
import { useFormState } from '../../lib/hooks';
import { FormValidityState } from '../../lib/types';
import Input from '../Input';
import SocialMedia from '../SocialMedia/SocialMedia';
import SVGIcon from '../SVGIcon/SVGIcon';
import './Footer.scss';

const Footer = (): JSX.Element => {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement | null>(null);
  const formState = useFormState<FormValidityState>(formRef);

  const onSearch = (e: FormEvent) => {
    e.preventDefault();
    navigate(`${ROUTES.jobboard.path}?page=1&page_size=10&search=${formState.values.search.replace(/ /g, '+')}`);
    formRef.current?.reset();
  }
  
  return (
    <footer>
      <div className='content'>
        <Link to='/' className='logo'>
          <SVGIcon id='logo-w-tagline' color='white' />
        </Link>

        <div className='contact-wrap'>
          <div>
            <p className='eyebrow'>Contact</p>
            <a href='mailto: info@jobsnob.net' target='_blank' rel='noreferrer'>info@jobsnob.net</a>
            <a href='tel: (424) 222-9809'>424.222.9809</a>
          </div>

          <div>
            <p className='eyebrow'>Social</p>
            <SocialMedia size={21} fbColor='white' altFB={true} instaColor='white' inColor='white' altOrder={true} />
          </div>
        </div>

        <form ref={formRef} onSubmit={onSearch}>
          <Input 
            icon='arrow'
            iconColor='white'
            onIcon={onSearch}
            attributes={{ type: 'text', id: 'search', required: true, placeholder: 'Search'}} 
          />
          <div className='btn-wrap'>
            <Link to={ROUTES.jobboard.path} className='btn btn--border'>Find a job</Link>
            <Link to={ROUTES.dashboard.path.replace(':pageID', 'drafts') + '?post=new'} className='btn btn--border'>Post a job</Link>
          </div>
          <p className='eyebrow'>© {new Date().getFullYear()}. ALL RIGHTS RESERVED - JOB SNOB</p>
        </form>

      </div>
    </footer>
  )
};

export default Footer;