import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../lib/app-routes';
import { LifeCycleValue } from '../../lib/types';
import Modal from '../Modal/Modal';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './EmployerJobCard.module.scss';

interface JobItemMenuProps {
  life_cycle: LifeCycleValue;
  newCount: number;
  collapseMenu: boolean;
  jobID: string;
  collapseMenuToggle: () => void;
  showApplicants: () => void;
  onDelete: () => void;
  onReactivate: () => void;
}

const JobCardMenu = (props: JobItemMenuProps): JSX.Element => {
  const [ showDeleteModal, setShowDeleteModal ] = useState(false);
  const [ showModifyModal, setShowModifyModal ] = useState(false);

  const onDeleteConfirmed = () => {
    props.onDelete();
  }

  return (
    <div className={styles.menuWrap}>
      {
        !!props.newCount && props.life_cycle === 'active' &&
        <button className='btn btn--sand' onClick={props.showApplicants}>
          <SVGIcon id='people' />
          {props.newCount}
          <span>New Applicant{props.newCount > 1 ? 's' : ''}</span>
        </button>
      }

      {
        (props.life_cycle === 'deactivated' || props.life_cycle === 'expired') &&
        <button className='btn btn--oval' data-desktop onClick={props.onReactivate}>
          <SVGIcon id='redo' color='white' />
          Reactivate
        </button>
      }
      
      {
        props.life_cycle !== 'deactivated' && props.life_cycle !== 'expired' &&
        <>
          <div className={styles.hiddenBtnWrap} data-show={!props.collapseMenu}>
            {
              props.life_cycle === 'active' && 
              <>
                <button className='btn btn--white btn--oval btn--border' onClick={() => setShowModifyModal(true)}>
                  request for changes
                </button>

                <button className='btn btn--destructive-alt btn--oval btn--border' onClick={() => setShowDeleteModal(true)}>
                  delete
                </button>
              </>
            }
            
            <Link className='btn btn--white btn--oval btn--border'
              to={ROUTES.job.path.replace(':uuid', props.jobID)}>
              view
            </Link>
          </div>

          <button className={styles.menuBtn} data-show={props.collapseMenu} onClick={props.collapseMenuToggle}>
              <SVGIcon id='dot-menu' color='accent-grey' />
          </button>
        </>
      }
      <Modal show={showDeleteModal} onCloseBtn={() => setShowDeleteModal(false)}>
        <p>You are about to <span className='bold'>DELETE</span> a job post and this action cannot be undone.</p>
        <div className={styles.modalBtnWrap} data-modal>
          <button type='button' className='btn btn--white btn--border btn--auto-width' onClick={() => setShowDeleteModal(false)}>Cancel</button>
          <button type='button' className='btn btn--auto-width' onClick={onDeleteConfirmed}>Delete</button>
        </div>
      </Modal>
      <Modal show={showModifyModal} onCloseBtn={() => setShowModifyModal(false)}>
        <p>Any edits to your post must be sent to&nbsp;
          <a href='mailto:Job Snob <info@jobsnob.net>?subject=Job Post Modification Request&body=Please include the following information, and a Job Snob team member will be in touch shortly.%0D%0A%0D%0AFull Name -%0D%0APhone Number -%0D%0AEmail -%0D%0ACurrent title of post to edit -%0D%0ADesired edits -%0D%0AOther relevant information -%0D%0A%0D%0AThank you,%0D%0AThe Job Snob Team'
          target='_blank'
          rel='noreferrer'
          className='underline'
        >
            info@jobsnob.net
          </a>
        </p>
        <div className={styles.modalBtnWrap} data-modal>
          <button type='button' className='btn btn--white btn--border btn--auto-width' onClick={() => setShowModifyModal(false)}>Close</button>
        </div>
      </Modal>
    </div>
  )

};

export default JobCardMenu;