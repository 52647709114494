import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { map } from 'rxjs';
import { useFormState, useJobChoiceDetails, useSubject } from '../../lib/hooks';
import AdminService from '../../lib/services/admin.service';
import GMapsService from '../../lib/services/gmaps.service';
import { FormValidityState, ErrorResponse, JobPostData, InputAutoCompleteCallbackData } from '../../lib/types';
import { jobForReview$ } from '../../pages/Dashboard/state';
import { job$ } from '../../pages/Jobboard/state';
import { markdownOutput$, markdownInput$ } from '../Editor/state';
import EditorTextArea from '../EditorTextArea';
import Input from '../Input';
import InputAutoComplete from '../InputAutoComplete/InputAutoComplete';
import RadioGroup from '../RadioGroup';
import Select from '../Select';
import SelectAddMulti from '../SelectAddMulti/SelectAddMulti';
import TextAreaAddMulti from '../TextAreaAddMulti/TextAreaAddMulti';
import styles from './DraftJobPost.module.scss';


const AdminEditJobPost = (props: {location: 'dashboard' | 'jobboard'}): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const jobData = useSubject(props.location === 'dashboard' ? jobForReview$ : job$);
  const formRef = useRef<HTMLFormElement | null>(null);
  const formState = useFormState<FormValidityState>(formRef);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [stateAbbr, setStateAbbr] = useState<string>();
  const [otherState, setOtherState] = useState<string>();
  const [latitude, setLatitude] = useState<string | number>();
  const [longitude, setLongitude] = useState<string | number>();
  const {jobCategoryOptions, jobDurationOptions, jobPostLifeCycleOptions, stateOptions} = useJobChoiceDetails();
  const [ disable, setDisable ] = useState(false);
  /* uncomment below when recruiter dashboard is complete */
  /* const [ recruiterOptions, setRecruiterOptions ] = useState<SelectOption[]>(); */
  const markdown = useSubject(markdownOutput$);

  useEffect(() => {
    if (jobData) markdownInput$.next(jobData.description);
  }, [jobData])

  useEffect(() => {
    if (jobData) {
      setLatitude(jobData.latitude);
      setLongitude(jobData.longitude);
    }
    document.body.scrollTo(0,0);
  }, [jobData])

  /* uncomment below when recruiter dashboard is complete */
  /* useEffect(() => {
    const sub = AdminService.getUsers('page=1&page_size=30&role=recruiter').subscribe({
      next: p => {
        const options: SelectOption[] = p.results.filter(u => u.is_active).map(user => {
          return {
            label: `${(user.company as CompanyData).name} - ${user.email}`,
            value: user.uuid
          }
        });
        setRecruiterOptions([{label: '', value: 'default'}, ...options]);
      },
      error: err => console.log(err)
    })
    //
    return () => {
      if (!sub.closed) sub.unsubscribe();
    }
  }, []) */

  const onCancel = () => {
    searchParams.delete('edit');
    setSearchParams(searchParams);
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (error) setError(null);
    setDisable(true);
    const values: JobPostData = {...formState.values as JobPostData};
    for (const [key, value] of Object.entries(values)) {
      if (value === 'default') (values as {[key: string]: any})[key] = undefined;
    }
    values.latitude = latitude;
    values.longitude = longitude;
    values.additional_notification_emails = values.additional_notification_emails?.filter(v => v !== '');
    if (!values.state) values.state = 'default';
    if (!values.key_responsibilities) values.key_responsibilities = [];
    if (!values.qualifications) values.qualifications = [];
    if (values.category_other && !values.categories.includes('other')) values.category_other = '';
    if (values.expiration_date === '') delete values.expiration_date;
    if (jobData?.state_other && values.state !== '--') values.state_other = '';
    if (props.location === 'jobboard') values.life_cycle = 'active';
    //
    AdminService.updateJob(jobData?.uuid as string, values).subscribe({
      next: (dta) => {
        location.pathname.includes('jobboard') ? job$.next(dta) : jobForReview$.next(dta);
        onCancel();
      },
      error: (err) => {setDisable(false); setError(err)}
    });
  }

  const onCitySelected = (obj: InputAutoCompleteCallbackData) => {
    if (obj.terms) {
      const val = stateOptions?.find((o => o.value === obj.terms[1].value || o.value === obj.terms[obj.terms.length - 1].value))?.value;
      setStateAbbr(val || '--');
      if (!val) setOtherState(obj.terms[1].value);
      else if (otherState) setOtherState(undefined);
    }
    //
    if (obj.placeID) {
      GMapsService.getDetails(obj.placeID, ['geometry'])
        .pipe(map(dta => dta.geometry?.location))
        .subscribe({
          next: (loc) => {
            setLatitude(parseFloat(loc?.lat().toFixed(9) as string));
            setLongitude(parseFloat(loc?.lng().toFixed(9) as string));
          }
        }
      )
    }
  }
  
  return (
    <>
      <div className={`content ${styles['content']}`}>
        <div className={styles.header}>
          <h2>Edit Job Post</h2>
          {/* <button type='button' className={styles.deleteBtn} disabled={disable} onClick={() => setShowModal(true)}>
            <SVGIcon id='trash' />
          </button> */}
        </div>

        <form ref={formRef} id='draft' onSubmit={onSubmit}>
          {/* Title etc. */}
          <div className={styles.pallet}>
            <div>
              <RadioGroup label='Interested in Help from a Recruiter?*'
                options={[
                  {label: 'Yes', value: 'true'},
                  {label: 'No', value: 'false'}
                ]}
                attributes={{ id: 'requires_recruiter', required: true, defaultValue: jobData?.requires_recruiter.toString() }}
              >
                <p>We&apos;ll reach out to you later to see how our snobs can help get you the best candidate!</p>
              </RadioGroup>

              <RadioGroup label='Wanna be exclusive?*'
                options={[
                  {label: 'Yes', value: 'true'},
                  {label: 'No', value: 'false'}
                ]}
                attributes={{ id: 'is_exclusive', required: true, defaultValue: jobData?.is_exclusive.toString() }}
              >
                <p>
                  Only check this box if you plan to list this job on Job Snob alone. If selected, this job will be given extra prominence in search results because we like being in exlusive relationships.
                  We reserve the right to remove the listing and notify you, if this post is found elsewhere, per our terms of service.
                </p>
              </RadioGroup>
              
              <RadioGroup label='Confidential?*'
                options={[
                  {label: 'Yes', value: 'true'},
                  {label: 'No', value: 'false'}
                ]}
                attributes={{ id: 'is_confidential', required: true, defaultValue: jobData?.is_confidential.toString() || 'false' }}
              >
                <p>
                  Please check &quot;Yes&quot; if this job post is confidential. 
                  If a candidate reaches out to inquire about the employer, we will not provide information if &quot;Yes&quot; is checked.
                </p>
              </RadioGroup>
              
              {
                jobData?.company_name !== 'Job Snob' &&
                <RadioGroup label='Assign to Job Snob?*'
                  options={[
                    {label: 'Yes', value: 'true'},
                    {label: 'No', value: 'default'}
                  ]}
                  attributes={{ id: 'assign_to_job_snob', required: true, defaultValue: 'default' }}
                >
                  <p>
                    Please check &quot;Yes&quot; if you would like to transfer ownsership of this job to Job Snob.
                  </p>
                </RadioGroup>
              }
              
            </div>

            <Input label='Job Title*'
              forceError={!!error}
              errorMessage={error ? error.response.title : undefined }
              attributes={{ type: 'text', id: 'title', required: true, 
                          defaultValue: jobData?.title }} 
            />

            <InputAutoComplete
              label='City*'
              forceError={!!error}
              errorMessage={error ? error.response.city : undefined }
              completeType='city'
              valueAs='label'
              attributes={{ id: 'city', type: 'text', required: true, defaultValue: jobData?.city }}
              onValueSet={onCitySelected}
            />

            {
              stateOptions &&
              <Select label='State'
                options={stateOptions}
                forceError={!!error}
                errorMessage={error ? error.response.state : undefined }
                attributes={{ id: 'state', defaultValue: stateAbbr || jobData?.state  }}
              />
            }

            {
              (formState?.values.state === '--') &&
              <Input label='Other State (non-US state or province)*'
                forceError={!!error}
                errorMessage={error ? error.response.state_otherr : undefined }
                attributes={{ type: 'text', id: 'state_other', required: true, 
                            defaultValue: otherState || jobData?.state_other }} 
              />
            }

            {
              jobDurationOptions &&
              <Select label='Select Type of Job*'
                options={jobDurationOptions}
                forceError={!!error}
                errorMessage={error ? error.response.duration : undefined }
                attributes={{ id: 'duration', required: true, defaultValue: jobData?.duration }}
              />
            }

            {
              jobCategoryOptions &&
              <SelectAddMulti label='Select Job Categories*'
                options={jobCategoryOptions}
                forceError={!!error}
                errorMessage={error ? error.response.categories : undefined }
                attributes={{ id: 'categories', required: true }}
                defaultValues={jobData?.categories}
              />
            }

            {
              formState?.values.categories?.includes('other') &&
              <Input label='Category Other*'
                forceError={!!error}
                errorMessage={error ? error.response.category_other : undefined }
                attributes={{ type: 'text', id: 'category_other', required: true, 
                            defaultValue: jobData?.category_other, placeholder: 'Type in Other Category' }} 
              />
            }

            <div className='form-sub-group form-sub-group--border'>
              <label htmlFor='expiration_date'>Expiration Date</label>
              <input className='has-label' type='date' name='expiration_date'
                    defaultValue={jobData?.expiration_date ? jobData.expiration_date.substring(0,10) : undefined} />
            </div>

            {/* uncomment below when recruiter dashboard is complete */}
            {/* {
              recruiterOptions &&
              <Select label='Transfer Ownership'
                options={recruiterOptions}
                forceError={!!error}
                errorMessage={error ? error.response.recruiter : undefined }
                attributes={{ id: 'recruiter'}}
              />
            } */}

            {/* Description */}
            <EditorTextArea label='Job Description*' 
              forceError={!!error}
              errorMessage={error?.response.description}
              attributes={{id: 'description', required: true, defaultValue: markdown || undefined}}
            />
          </div>

          {/* Responsibilities */}
          <div className={styles.pallet}>
            <TextAreaAddMulti className={styles.textAreaContainer}
              label='Key Job Responsibilities*'
              forceError={!!error}
              errorMessage={error?.response.key_responsibilities}
              defaultValues={jobData?.key_responsibilities}
              attributes={{name: 'key_responsibilities', rows: 1, required: true, inputMode: 'text', maxLength: 256,
                          placeholder: 'Write responsibilities here (6 Max). Snob Tip: Keep them short and sweet!' }}
            />
          </div>

          {/* Qualifications */}
          <div className={styles.pallet}>
            <TextAreaAddMulti className={styles.textAreaContainer}
              label='Applicant Qualifications*'
              forceError={!!error}
              errorMessage={error?.response.qualifications}
              defaultValues={jobData?.qualifications}
              attributes={{name: 'qualifications', rows: 1, required: true, inputMode: 'text', maxLength: 256,
                          placeholder: 'Write qualifications here (6 Max).' }}
            />
          </div>
          
          {/* Compensation */}
          <div className={styles.pallet}>
            <div className={`form-sub-group form-sub-group--column ${styles.textAreaContainer}`}>
              <label>Compensation</label>
              <textarea id='compensation' name='compensation' inputMode='text' rows={1} 
                defaultValue={jobData?.compensation}
                placeholder='(Optional) Write compensation here.'></textarea>
              {
                error && error.response.compensation &&
                <p className='input-error'>{error.response.compensation}</p>
              }
            </div>
          </div>

          {/* Email */}
          <div className={styles.pallet}>
            <TextAreaAddMulti className={styles.textAreaContainer}
              label='Add Notification Emails (Optional)'
              forceError={!!error}
              errorMessage={error ? error?.response.additional_notification_emails : 'Email address is not valid.'}
              defaultValues={jobData?.additional_notification_emails}
              attributes={{name: 'additional_notification_emails', rows: 1, inputMode: 'email', maxLength: 256 }}
            />
          </div>
        </form>
        
        <div className={styles.btnWrap}>
          <button type='button' className='btn btn--white btn--border' disabled={disable} onClick={onCancel}>
            Cancel
          </button>

          <button type='submit' form='draft' className='btn' disabled={disable}>
            Save Changes
          </button>

          {/* <button type='button' className='btn btn--border btn--destructive-alt' 
            disabled={disable} onClick={() => setShowModal(true)}>
            Delete
          </button> */}
        </div>
      </div>
      {/* <Modal show={showModal} onCloseBtn={() => setShowModal(false)}>
        <p>You are about to delete a draft and this action cannot be undone.</p>
        <div className={styles.btnWrap} data-modal>
          <button type='button' className='btn btn--white btn--border btn--auto-width' onClick={() => setShowModal(false)}>Cancel</button>
          <button type='button' className='btn btn--auto-width' onClick={onDeleteDraft}>Delete</button>
        </div>
      </Modal> */}
    </>
  )
};

export default AdminEditJobPost;