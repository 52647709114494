import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { Subscription } from 'rxjs';
import DraftJobPost from '../../components/DraftJobPost/DraftJobPost';
import { useQuery, useSubject } from '../../lib/hooks';
import EmployerJobService from '../../lib/services/employer.job.service';
import { userData$, loggedIn$ } from '../../lib/state';
import { ErrorResponse, JobPostData, UserData } from '../../lib/types';
import styles from './Dashboard.module.scss';
import DashboardNav from './DashboardNav/DashboardNav';
import DashboardList from './DashboardList/DashboardList';
import { dashboardErrorMsg$, newApplicantsCount$ } from './state';
import { DashboardPage } from './types';
import ApplyModal from '../../components/ApplyModal/ApplyModal';
import ApplySuccessModal from '../../components/ApplySuccessModal/ApplySuccessModal';
import CreditsService from '../../lib/services/credits.service';
import Modal from '../../components/Modal/Modal';
import AdminDashboardList from './DashboardList/AdminDashboardList';
import AdminReviewJobPost from '../../components/AdminReviewJobPost/AdminReviewJobPost';
import AdminDashboardNav from './DashboardNav/AdminDashboardNav';
import AdminViewUser from '../../components/AdminViewUser/AdminViewUser';
import AdminEditJobPost from '../../components/DraftJobPost/AdminEditJobPost';
import AdminViewAndApply from '../../components/AdminReviewJobPost/AdminViewAndApply';
import ApplicantReview from '../../components/ApplicantReview/ApplicantReview';
import CreateUserForm from '../../components/CreateUserForm/CreateUserForm';
import { ROUTES } from '../../lib/app-routes';
import Logger from '../../lib/logger';

const Dashboard = (): JSX.Element => {
  const userData = useSubject<UserData | null>(userData$);
  const { pageID } = useParams() as {pageID: DashboardPage };
  const [{ edit, post, apply, applySuccess, approve, approveID, selectID, activateID, view }] = useQuery();
  const newApplicantsCount = useSubject<number>(newApplicantsCount$);
  const [editDraftData, setEditDraftData]  = useState<JobPostData>();
  const errorMessage = useSubject(dashboardErrorMsg$);
  const loggedIn = useSubject(loggedIn$);
  const navigate = useNavigate();

  /* const [modalKey, setModalKey] = useState(0); */

  /* const updateModalKey = () => {
    setModalKey(modalKey+1);
  }; */

  useEffect(() => {
    if (loggedIn && userData?.requires_onboarding) {
      Logger.log('Redirecting user to on-boarding from Dashboard.');
      navigate(ROUTES.onboarding.path.replace(':step', 'complete-profile'));
    }

    if (userData?.role === 'employer') {
      EmployerJobService.getNewApplicationCount().subscribe();
      CreditsService.getAvailableCredits().subscribe();
    }
  }, [userData])

  useEffect(() => {
    let sub: Subscription;
    //
    if (edit && userData?.role === 'employer') {
      sub = EmployerJobService.getJob(edit).subscribe({
        next: dta => setEditDraftData(dta),
        error: (err: ErrorResponse) => dashboardErrorMsg$.next(`ERROR: ${err.response.detail || err.message}`)
      })
    }
    //
    return () => {
      if (sub && !sub.closed) sub.unsubscribe();
    }
  }, [edit]);
  
  return (
    <>
      <Helmet>
        <title>Job Snob: Dashboard </title>
      </Helmet>
      <div className='content-wrap content-wrap--no-padding bg-account'>
        {
          userData &&
          <>
            <div className={styles.header}>
            <div className='eyebrow-hl-wrap'>
              <p className='eyebrow'>{userData.role}</p>
              <h1><span>Dashboard</span></h1>
            </div>
            {
              userData.role === 'administrator' &&
              <AdminDashboardNav pageID={pageID} />
            }
            {
              userData.role !== 'administrator' &&
              <DashboardNav role={userData.role} pageID={pageID} notifyCountLeft={newApplicantsCount} />
            }
          </div>
          {
            userData.role === 'employer' && 
            <>
              {
                pageID === 'jobs' &&
                <>

                  {
                    !edit &&
                    <>
                      <Helmet>
                        <title>Job Snob: Dashboard - Jobs</title>
                      </Helmet>
                      <DashboardList type='employer' pageID='jobs' />
                    </>
                  }
                  {
                    edit && editDraftData &&
                    <>
                      <Helmet>
                        <title>Job Snob: Dashboard - Edit Draft</title>
                      </Helmet>
                      <DraftJobPost user={userData} key={editDraftData.uuid} mode='edit' jobData={editDraftData} />
                    </>
                  }
                </>
              }
              {
                pageID === 'drafts' &&
                <>
                  {
                    !edit && !post &&
                    <>
                      <Helmet>
                        <title>Job Snob: Dashboard - Drafts</title>
                      </Helmet>
                      <DashboardList type='employer' pageID='drafts' />
                    </>
                  }
                  {
                    edit && editDraftData &&
                    <>
                      <Helmet>
                        <title>Job Snob: Dashboard - Edit Draft</title>
                      </Helmet>
                      <DraftJobPost user={userData} key={editDraftData.uuid} mode='edit' jobData={editDraftData} />
                    </>
                  }
                  {
                    !edit && post &&
                    <>
                      <Helmet>
                        <title>Job Snob: Dashboard - New Draft</title>
                      </Helmet>
                      <DraftJobPost user={userData} mode='new' />
                    </>
                    
                  }
                </>
              }
            </>
          }

          {
            userData.role === 'candidate' &&
            <>
              {
                pageID === 'jobs' &&
                <>
                  <Helmet>
                    <title>Job Snob: Dashboard - Jobs</title>
                  </Helmet>
                  <DashboardList type='candidate' pageID='jobs' />
                </>
              }
              {
                pageID === 'applications' &&
                <>
                  <Helmet>
                    <title>Job Snob: Dashboard - Applications</title>
                  </Helmet>
                  <DashboardList type='candidate' pageID='applications' />
                </>
                
              }
              {
                apply &&
                <>
                  <Helmet>
                    <title>Job Snob: Dashboard - Apply</title>
                  </Helmet>
                  <ApplyModal page='current' />
                </>
              }
              {
                applySuccess &&
                <>
                  <Helmet>
                    <title>Job Snob: Dashboard - Application Sent</title>
                  </Helmet>
                  <ApplySuccessModal />
                </>
              }
            </>
          }

          {
            userData.role === 'administrator' &&
            <>
              {
                pageID === 'create-candidate' &&
                <>
                  <Helmet>
                    <title>Job Snob: Dashboard - Create Candidate</title>
                  </Helmet>
                  <CreateUserForm role='candidate' />
                </>
              }

              {
                pageID === 'jobs' && !approve && !activateID && !edit && !view &&
                <>
                  <Helmet>
                    <title>Job Snob: Dashboard - Jobs</title>
                  </Helmet>
                  <AdminDashboardList pageID='jobs' />
                </>
              }

              {
                pageID === 'jobs' && !!activateID && !edit &&
                <>
                  <Helmet>
                    <title>Job Snob: Dashboard - Reactivate {activateID}?</title>
                  </Helmet>
                  <AdminReviewJobPost type='inactive' />
                </>
              }

              {
                pageID === 'jobs' && !!view &&
                <>
                  <Helmet>
                    <title>Job Snob: Dashboard - View Job {view}?</title>
                  </Helmet>
                  <AdminReviewJobPost type='active' />
                </>
              }

              {
                pageID === 'apply-by-proxy' && !selectID &&
                <>
                  <Helmet>
                    <title>Job Snob: Dashboard - Apply By Proxy (Step 1: Search Jobs)</title>
                  </Helmet>
                  <AdminDashboardList pageID='apply-by-proxy' />
                </>
              }

              {
                pageID === 'apply-by-proxy' && selectID &&
                <>
                  <Helmet>
                    <title>Job Snob: Dashboard - Apply By Proxy (Step 2: Find Candidates)</title>
                  </Helmet>
                  <AdminViewAndApply />
                </>
              }

              {
                pageID === 'modifications' && !approve &&
                <>
                  <Helmet>
                    <title>Job Snob: Dashboard - Review Modified Jobs</title>
                  </Helmet>
                  <AdminDashboardList pageID='modifications' />
                </>
              }

              {
                pageID === 'users' && !approve &&
                <>
                  <Helmet>
                    <title>Job Snob: Dashboard - Users</title>
                  </Helmet>
                  <AdminDashboardList pageID='users' />
                </>
              }

              {
                pageID === 'applications' && !approve &&
                <>
                  <Helmet>
                    <title>Job Snob: Dashboard - Review New Applications</title>
                  </Helmet>
                  <AdminDashboardList pageID='applications' />
                </>
              }

              {
                (approve === 'new' || approve === 'modified') && approveID && !edit &&
                <>
                  <Helmet>
                    <title>Job Snob: Dashboard - Review Job Post {approveID}</title>
                  </Helmet>
                  <AdminReviewJobPost type={approve as 'new' | 'modified'} />
                </>
              }

              {
                edit &&
                <>
                  <Helmet>
                    <title>Job Snob: Dashboard - Edit Job Post {edit}</title>
                  </Helmet>
                  <AdminEditJobPost key={edit} location='dashboard'/>
                </>
              }

              {
                approve === 'user' && approveID &&
                <>
                  <Helmet>
                    <title>Job Snob: Dashboard - View User {approveID}</title>
                  </Helmet>
                  <AdminViewUser uuid={approveID} />
                </>
              }

              {
                approve === 'application' && approveID &&
                <>
                  <Helmet>
                    <title>Job Snob: Dashboard - View Application {approveID}</title>
                  </Helmet>
                  <ApplicantReview uuid={approveID} />
                </>
              }
            </>
          }
          </>
        }
        <Modal show={!!errorMessage} onCloseBtn={() => dashboardErrorMsg$.next(null)}>
          <p className='eyebrow error'>{errorMessage}</p>
        </Modal>
      </div>
    </>
  )
};

export default Dashboard;