import React from 'react';
import { Helmet } from 'react-helmet';
import CheckoutComponent from '../../../components/CheckoutComponent/CheckoutComponent';
import { ROUTES } from '../../../lib/app-routes';
import styles from './Checkout.module.scss';


const Checkout = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <title>Job Snob: Employers Checkout</title>
      </Helmet>
      <div className={`content-wrap ${styles.contentWrap}`}>
        <CheckoutComponent returnUrl={ROUTES.checkoutSuccess.path} />
      </div>
    </>
  )
};

export default Checkout;