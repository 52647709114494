import React, { FormEvent, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import EmailSent from '../../../components/EmailSent/EmailSent';
import Input from '../../../components/Input';
import SoloNLIForm from '../../../components/SoloNLIForm/SoloNLIForm';
import { DEFAULT_FORM_VALIDITY_STATE } from '../../../lib/constants';
import { ROUTES } from '../../../lib/app-routes';
import { ErrorResponse, FormValidityState, VerifyEmailData } from '../../../lib/types';
import { getDeepClone } from '../../../lib/util.functions';
import { emailValidator } from '../../../lib/validator.functions';
import UserService from '../../../lib/services/user.service';

const VerifyEmail = (): JSX.Element => {
  const [formState, setFormState] = useState<FormValidityState>(getDeepClone(DEFAULT_FORM_VALIDITY_STATE));
  const [verified, setVerified] = useState(false);
  const [failed, setFailed] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const { uid, token } = useParams();

  const onVerify = () => {
    UserService.verifyEmail({ uid, token } as unknown as VerifyEmailData).subscribe({
      next: () => setVerified(true),
      error: () => setFailed(true)
    });
  }

  const onSubmit = (e: FormEvent): void => {
    e.preventDefault();
    const values: {email: string} = { ...formState.values as unknown as {email: string} };
    UserService.resendEmail(values).subscribe({
      next: () => setSent(true),
      error: (e: ErrorResponse) => setError(e)
    });
  };
  
  
  return (
    <>
      <Helmet>
      <title>Job Snob: Verify Email</title>
      </Helmet>
      {
        sent 
        ?
        <EmailSent eyebrow='email verification' notReceivedFn={() => setSent(false)}/>
        :
        <SoloNLIForm 
          title={failed ? 'Unable to Verify Your Account' : 'Verify Email'}
          eyebrow={failed ? 'Something went wrong!' : 'Almost there!'}
          onSubmit={onSubmit}
          setter={setFormState}
          defaultState={formState}
          contentWrapClass={verified || failed ? 'bg1-dark' : 'bg1-light'}
        >
          <>
            {
              failed &&
              <>
                <Input
                  forceError={!!error}
                  errorMessage={error?.response.email || 'email is not valid'}
                  validatorFns={[emailValidator]}
                  attributes={{type: 'email', id: 'email', placeholder: 'Type your email here',
                              required: true, autoComplete: 'email' }} 
                />

                <div className='add-margin'>
                  <button className='btn' type='submit'>Resend Email Verification</button>
                </div>
              </>
            }

            {
              verified &&
              <div className='form-sub-group' style={{justifyContent: 'center'}}>
                <p className='success-txt'>Account Verified!</p>
                <Link to={ROUTES.login.path}>
                  Back to Log In
                </Link>
              </div>
            }

            {
              !failed && !verified &&
              <button className='btn' type='button' onClick={onVerify}>Verify</button>
            }
          </>
        </SoloNLIForm>
      }
      
    </>
  )
};

export default VerifyEmail;