import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../lib/app-routes';
import { useSubject } from '../../lib/hooks';
import WidgetService from '../../lib/services/widget.service';
import { loggedIn$, userData$ } from '../../lib/state';
import { ApplicantData, JobPostData } from '../../lib/types';
import { job$ } from '../../pages/Jobboard/state';
import FavButton from '../FavButton/FavButton';
import JobDetail from '../JobDetail/JobDetail';
import OnboardingApplyRedirect from '../OnboadringApplyRedirect';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './CandidateJobCard.module.scss';

interface CandidateJobCardProps {
  type: 'job' | 'application';
  jobData: JobPostData | ApplicantData;
  disabled?: boolean;
  hideLink?: boolean;
  isWidget?: boolean;
  hideDescription?: boolean;
  onFavCallback?: (dta: {uuid: string; is_favorited: boolean}) => void
}

const CandidateJobCard = (props: CandidateJobCardProps): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [jobData] = useState((props.jobData as ApplicantData).job || props.jobData);
  const loggedIn = useSubject(loggedIn$);
  const userData = useSubject(userData$);
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState(false);

  const handleRedirect = () => {
    if (redirect) {
      setRedirect(false);
      setTimeout(() => {
        setRedirect(true);
      }, 200);
    } else {
      setRedirect(true);
    }
  }

  const onApplyBtn = () => {
    if (userData && loggedIn) {
      if (userData.requires_onboarding) {
        navigate(ROUTES.onboarding.path.replace(':step', 'complete-profile'));
      } else {
        searchParams.append('apply', jobData.uuid)
        setSearchParams(searchParams.toString());
      }
    } else {
      handleRedirect();
    }
  }

  const onFavClick = () => {
    if (!loggedIn) handleRedirect();
  }
  
  return (
    <div className={`job-card ${styles['container']}`} data-type={props.type}>
      {
        redirect &&
        <OnboardingApplyRedirect jobData={jobData} isWidget={props.isWidget}/>
      }
      <div className={`job-card-title-menu-wrap ${styles.titleMenuWrap}`} data-type={props.type}>
        <h3>
          {jobData.title}
          {
            // Company and employer name is provided only if the requesting user
            // is an admin or recruiter.
            (jobData.company_name || jobData.employer_name) &&
            <span className='company-name'> - {jobData.company_name} ({jobData.employer_name})</span>
          }
        </h3>
        {
          props.type === 'job' && !props.disabled &&
          <div className={styles.menu}>
            <button data-desktop className={`btn btn--auto-width${props.isWidget ? ' btn--widget-blue' : ''}`} 
              disabled={jobData.has_applied}
              onClick={onApplyBtn}>
              {jobData.has_applied ? 'Already Applied' : 'Apply'}
            </button>
            <FavButton 
              jobID={jobData.uuid}
              isWidget={props.isWidget}
              isFavorited={jobData.is_favorited} 
              onFavCallback={(dta) => {if (props.onFavCallback) props.onFavCallback(dta)}}
              onClick={onFavClick}
            />
          </div>
        }
      </div>

      <div className='job-card-details-wrap'>
        <JobDetail icon='building' text={jobData.duration} />
        <JobDetail icon='map-pin' text={`${jobData.city}, ${jobData.state === '--' ? jobData.state_other : jobData.state}`} />
        {
          jobData.is_exclusive &&
          <JobDetail icon='diamond' text='Job Snob Exclusive' />
        }
        {
          (props.jobData as ApplicantData).age &&
          <JobDetail icon='clock' text={(props.jobData as ApplicantData).age} />
        }
      </div>

      {
        props.type === 'job' && !props.hideDescription &&
        <div className={styles.desc}>
          <ReactMarkdown>{jobData.description}</ReactMarkdown>
        </div>
        
      }

      {
        !props.hideLink && !props.isWidget &&
        <Link className={styles.link} onClick={() => job$.next(jobData)}
          to={ROUTES.job.path.replace(':uuid', jobData.uuid)}>
          See More
          <SVGIcon id='arrow' />
        </Link>
      }

      {
        !props.hideLink && props.isWidget &&
        <a className={styles.link} 
          onClick={() => WidgetService.postHref(ROUTES.job.path.replace(':uuid', jobData.uuid))}>
          See More
          <SVGIcon id='arrow' />
        </a>
      }
      
      {
        !props.hideLink &&
        <button data-mobile className={`btn${props.isWidget ? ' btn--widget-blue' : ''}`} onClick={onApplyBtn} disabled={props.disabled || jobData.has_applied}>
          {jobData.has_applied ? 'Already Applied' : 'Apply'}
        </button>
      }
      
    </div>
  )
   
}

export default CandidateJobCard