import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import ApplyModal from '../../../components/ApplyModal/ApplyModal';
import ApplySuccessModal from '../../../components/ApplySuccessModal/ApplySuccessModal';
import { job$ } from '../state';
import { useObservable, useQuery, useSubject } from '../../../lib/hooks';
import CandidateJobService from '../../../lib/services/candidate.job.service';
import { userData$ } from '../../../lib/state';
import styles from './Job.module.scss';
import JobDisplay from '../../../components/JobDisplay/JobDisplay';
import OnboardingApplyRedirect from '../../../components/OnboadringApplyRedirect';
import AdminEditJobPost from '../../../components/DraftJobPost/AdminEditJobPost';
import AdminService from '../../../lib/services/admin.service';
import RecruiterService from '../../../lib/services/recruiter.service';
import { ROUTES } from '../../../lib/app-routes';
import Modal from '../../../components/Modal/Modal';
import { DOMAIN } from '../../../lib/constants';

const Job = (): JSX.Element => {
  const [{apply, applySuccess, edit}, setQuery, searchParams] = useQuery();
  const {uuid} = useParams();
  const userData = useSubject(userData$);
  const [redirect, setRedirect] = useState(false);
  const jobData = useSubject(job$);
  const [data, error] = useObservable(CandidateJobService.getJob(uuid as string));
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [modalContent, setModalContent] = useState<'delete' | 'error'>();

  const onApply = () => {
    if (userData) {
      if (userData.requires_onboarding) {
        navigate(ROUTES.onboarding.path.replace(':step', 'complete-profile'));
      } else {
        setQuery(`apply=${jobData?.uuid}`);
      }
    } else {
      setRedirect(true);
    }
  }

  const onEdit = () => {
    job$.next(jobData);
    searchParams.set('edit', uuid as string);
    setQuery(searchParams);
  }

  const onError = () => {
    setShowModal(false);
    setTimeout(() => {
      setModalContent('error');
      setDisabled(false);
      setShowModal(true);
    }, 1000);
  }

  const onDelete = () => {
    setModalContent('delete');
    setShowModal(true);
  }

  const onDeleteConfirmed = () => {
    setDisabled(true);
    const ob$ = userData?.role === 'administrator' ? AdminService.deleteJob(uuid as string) : RecruiterService.deleteJob(uuid as string);
    ob$.subscribe({
      next: () => job$.next(null),
      error: onError,
      complete: () => navigate(ROUTES.jobboard.path),
    })
  }

  useEffect(() => {
    if (data) {
      job$.next(data);
    }
  }, [data])

  useEffect(() => {
    document.body.scrollTo(0,0);
  }, [edit])

  useEffect(() => {
    // Add a script tag
    const script = document.createElement('script');

    // Fill the tag content with JSON-LD data from the API
    fetch(`${DOMAIN}/api/jobs/${uuid}?serializer=google-jobs`)
    .then(response => response.text())
    .then(structuredDataText => {
      // Set the script type
      script.setAttribute('type', 'application/ld+json');

      // Set the script content
      script.textContent = structuredDataText;
      // TODO: Allegedly, Google wants the "description" property to be
      // formatted as HTML. Rich-results test tool does not complain about the
      // plaintext. If descriptions in the Google Jobs search engine don't
      // appear correctly, then this would be the first place to start fixing
      // them.
      //    https://developers.google.com/search/docs/appearance/structured-data/job-posting#job-posting-definition
      //    https://search.google.com/test/rich-results

      // Append the script to the DOM
      document.head.appendChild(script);
    });

    return () => {
      // Remove the script when the component is unmounted
      script.remove();
    }
  }, [])

  return (
    <>
      {
        !edit && 
        <>
          <Helmet>
            <title>Job Snob: Job{jobData ? ` - ${jobData.title}` : '' }</title>
          </Helmet>
          {
            redirect && jobData &&
            <OnboardingApplyRedirect jobData={jobData} />
          }
          <div className={`content-wrap ${styles.contentWrap}`}>
            <JobDisplay data={jobData} error={error} role={userData?.role || undefined} />
            {
              !!jobData &&
              <div className={styles.btnWrap}>
                {
                  (!userData || userData.role === 'candidate') &&
                  <button key={jobData.has_applied ? 1 : 0} className='btn btn--dt-wide' 
                    disabled={jobData.has_applied || jobData.life_cycle !== 'active'} 
                    onClick={onApply}>
                    {jobData.has_applied ? 'Already Applied' : 'Apply'}
                  </button>
                }

                {
                  (userData?.role === 'administrator' || userData?.role === 'recruiter') &&
                  <>
                    <button className='btn' onClick={onEdit}>
                      Edit
                    </button>
                    <button className='btn btn--destructive' onClick={onDelete}>
                      Delete
                    </button>

                    <Modal show={showModal} onCloseBtn={() => setShowModal(false)}>
                      <>
                        {
                          modalContent === 'delete' &&
                          <>
                            <p>You are about to <span className='bold uppercase'>delete</span> a job post and this action cannot be undone.</p>
                            <div className={styles.btnWrap} data-modal>
                              <button type='button' className='btn btn--white btn--border btn--auto-width' 
                                disabled={disabled} onClick={() => setShowModal(false)}>
                                Cancel
                              </button>
                              <button type='button' className='btn btn--auto-width' 
                                disabled={disabled} onClick={onDeleteConfirmed}>
                                Continue to Delete
                              </button>
                            </div>
                          </>
                        }
                        {
                          modalContent === 'error' &&
                          <>
                            <p className='eyebrow error' style={{width: '100%', textAlign: 'center', fontSize: '22px'}}>ERROR!</p>
                            <p>Something went wrong. Unable to <span className='bold uppercase'>delete</span> this job post.</p>
                          </>
                        }
                      </>
                    </Modal>
                  </>
                }
              </div>
            }
          </div>
        </>
      }

      {
        edit &&
        <>
          <Helmet>
            <title>Job Snob: Edit Job{jobData ? ` - ${jobData.title}` : '' }</title>
          </Helmet>
          <div className={`content-wrap ${styles.contentWrap}`} data-edit>
            <AdminEditJobPost key={jobData?.uuid || Math.random()} location='jobboard'/>
          </div>
        </>
      }
      
      {
        apply &&
        <>
          <Helmet>
            <title>Job Snob: Job - Apply</title>
          </Helmet>
          <ApplyModal page='jobboard' />
        </>
      }
      {
        applySuccess &&
        <>
          <Helmet>
            <title>Job Snob: Job - Application Sent</title>
          </Helmet>
          <ApplySuccessModal />
        </>
      }
    </>
  )
};

export default Job;