import React, { FormEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { map } from 'rxjs';
import { ROUTES } from '../../lib/app-routes';
import { useCredits, useFormState, useJobChoiceDetails, useSubject, useServerConstants } from '../../lib/hooks';
import EmployerJobService from '../../lib/services/employer.job.service';
import GMapsService from '../../lib/services/gmaps.service';
import { FormValidityState, ErrorResponse, JobPostData, LifeCycleValue, InputAutoCompleteCallbackData, UserData } from '../../lib/types';
import { dashboardErrorMsg$ } from '../../pages/Dashboard/state';
import { checkoutModuleShow$, checkoutReturnUrl$ } from '../../pages/Purchase/state';
import Input from '../Input';
import InputAutoComplete from '../InputAutoComplete/InputAutoComplete';
import Modal from '../Modal/Modal';
import RadioGroup from '../RadioGroup';
import Select from '../Select';
import SelectAddMulti from '../SelectAddMulti/SelectAddMulti';
import SnobTip from '../SnobTip/SnobTip';
import SVGIcon from '../SVGIcon/SVGIcon';
import TextAreaAddMulti from '../TextAreaAddMulti/TextAreaAddMulti';
import styles from './DraftJobPost.module.scss';
import { markdownInput$, markdownOutput$ } from '../Editor/state';
import EditorTextArea from '../EditorTextArea';
import { IS_SAFARI } from '../../lib/constants';


const DraftJobPost = (props: {user: UserData, mode: 'new' | 'edit', jobData?: JobPostData}): JSX.Element => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const formState = useFormState<FormValidityState>(formRef);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [stateAbbr, setStateAbbr] = useState<string>();
  const [otherState, setOtherState] = useState<string>();
  const [latitude, setLatitude] = useState<string | number | undefined>(props.jobData?.latitude);
  const [longitude, setLongitude] = useState<string | number | undefined>(props.jobData?.longitude);
  const {jobCategoryOptions, jobDurationOptions, jobPostLifeCycleOptions, stateOptions} = useJobChoiceDetails();
  const navigate = useNavigate();
  const [ showModal, setShowModal ] = useState(false);
  const [ disable, setDisable ] = useState(false);
  const [credits] = useCredits();
  const markdown = useSubject(markdownOutput$);
  const {serverConstants} = useServerConstants();

  useEffect(() => {
    if (props.jobData) markdownInput$.next(props.jobData.description);
  }, [props.jobData])

  function checkout(draft: JobPostData) {
    checkoutReturnUrl$.next(`${ROUTES.dashboard.path.replace(':pageID', 'jobs')}?autoSubmit=${draft.uuid}&checkoutSuccess=true`);
    checkoutModuleShow$.next(true);
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (credits === 0 && (props.jobData?.life_cycle !== 'active' || !props.jobData)) {
      onSaveDraft(e, true);
      return;
    }
    if (error) setError(null);
    setDisable(true);

    // Collect form data to submit
    const values: JobPostData = {...formState.values as JobPostData};
    values.latitude = latitude;
    values.longitude = longitude;
    values.additional_notification_emails = values.additional_notification_emails?.filter(v => v !== '');
    if (!values.state) values.state = 'default';
    if (!values.key_responsibilities) values.key_responsibilities = [];
    if (!values.qualifications) values.qualifications = [];
    if (props.jobData?.state_other && values.state !== '--') values.state_other = '';
    if (props.jobData?.category_other && !values.categories.includes('other')) values.category_other = '';
    if (props.jobData?.life_cycle !== 'active') values.life_cycle = 'review';

    // Determine if a GA event should send
    let sendGAEvent = true;  // User is submitting for review, therefore a posting event should be tracked
    if (serverConstants?.job_snob_company_uuid == props.user.company?.uuid) {
      // Don't report Job Snob employee activity
      sendGAEvent = false;
    }

    // Call the appropriate service
    let obs$;
    if (props.mode === 'edit' && props.jobData?.life_cycle === 'draft') {
      obs$ = EmployerJobService.updateJob(props.jobData?.uuid as string, values, sendGAEvent);
    } else if (props.mode === 'edit' && props.jobData?.life_cycle === 'active') {
      obs$ = EmployerJobService.modifyJob(props.jobData?.uuid as string, values);
    } else {
      obs$ = EmployerJobService.postJob(values, sendGAEvent);
    }

    // Handle the result of the service call
    obs$.subscribe({
      next: () => navigate(ROUTES.jobPostSubmitted.path),
      error: (err) => {setDisable(false); setError(err)}
    });
  }

  const onSaveDraft = (e: FormEvent, successCheckout?: boolean) => {
    e.preventDefault();
    if (error) setError(null);
    setDisable(true);
    Object.entries({...formState.values}).forEach(arr => {
      if(arr[1] === '' || arr[1] === 'default' ||
       (Array.isArray(arr[1]) && arr[1].length === 1 && arr[1][0] === '')) 
       delete formState.values[arr[0]];
    });

    // Collect form data to submit
    const values: JobPostData = {
      ...formState.values as JobPostData, 
      life_cycle: jobPostLifeCycleOptions?.find(o => o.label === 'Draft')?.value as LifeCycleValue
    };
    if (values.compensation === '') delete values.compensation;
    values.latitude = latitude;
    values.longitude = longitude;
    if (props.jobData?.state_other && values.state !== '--') values.state_other = '';
    if (props.jobData?.category_other && !values.categories.includes('other')) values.category_other = '';

    // Determine if a GA event should send
    let sendGAEvent = false;  // User is submitting a draft, therefore a posting event should never be tracked

    // Call the appropriate service
    let obs$;
    if (props.mode === 'new') {
      obs$ = EmployerJobService.postJob(values, sendGAEvent);
    }
    else {
      obs$ = EmployerJobService.updateJob(props.jobData?.uuid as string, values, sendGAEvent);
    }

    // Handle the result of the service call
    obs$.subscribe({
      next: (dta) => {
        if (successCheckout) {
          checkout(dta);
        } else {
          navigate(ROUTES.dashboard.path.replace(':pageID', 'drafts'));
        }
      },
      error: (err) => {setDisable(false); setError(err)},
      complete: () => setDisable(false)
    });
  }

  const onDeleteDraft = (e: MouseEvent) => {
    e.preventDefault();
    if (error) setError(null);
    setDisable(true);
    EmployerJobService.deleteDraft(props.jobData?.uuid as string).subscribe({
      next: () => navigate(ROUTES.dashboard.path.replace(':pageID', 'drafts')),
      error: (err) => {
        setDisable(false);
        setError(err);
        dashboardErrorMsg$.next(err.response.detail || err.message)
      }
    })
  }

  const onCitySelected = (obj: InputAutoCompleteCallbackData) => {
    if (obj.terms) {
      const val = stateOptions?.find((o => o.value === obj.terms[1].value || o.value === obj.terms[obj.terms.length - 1].value))?.value;
      setStateAbbr(val || '--');
      if (!val) setOtherState(obj.terms[1].value);
      else if (otherState) setOtherState(undefined);
    } 
    //
    if (obj.placeID) {
      GMapsService.getDetails(obj.placeID, ['geometry'])
        .pipe(map(dta => dta.geometry?.location))
        .subscribe({
          next: (loc) => {
            setLatitude(parseFloat(loc?.lat().toFixed(9) as string));
            setLongitude(parseFloat(loc?.lng().toFixed(9) as string));
          }
        }
      )
    }
  }

  useEffect(() => {
    if (error){
      setTimeout(() => {
        document.querySelector('.input-error')?.parentElement?.scrollIntoView(IS_SAFARI ? false : {block: 'center'});
      }, 100);
    }
  }, [error])

  useEffect(() => {
    console.count('DraftJobPost count');
  }, [])
  
  return (
    <>
      <div className={`content ${styles['content']}`}>
        <div className={styles.header}>
          {
            props.mode === 'new' && <h2>New Draft</h2>
          }
          {
            props.mode === 'edit' && props.jobData?.life_cycle !== 'active' && <h2>Edit Draft</h2>
          }
          {
            props.mode === 'edit' && props.jobData?.life_cycle === 'active' && <h2>Request Changes</h2>
          }
          {
            props.mode === 'edit' && props.jobData?.life_cycle !== 'active' &&
            <button type='button' className={styles.deleteBtn} disabled={disable} onClick={() => setShowModal(true)}>
              <SVGIcon id='trash' />
            </button>
          }
        </div>

        <form ref={formRef} id='draft' onSubmit={onSubmit}>
          {/* Title etc. */}
          <div className={styles.pallet}>
            <div className={styles.snobTipWrap}>
              <SnobTip />
            </div> 

            <Input label='Job Title*'
              forceError={!!error}
              errorMessage={error ? error.response.title : undefined }
              attributes={{ type: 'text', id: 'title', required: true, 
                          defaultValue: props.jobData?.title }} 
            />

            <InputAutoComplete
              label='City*'
              forceError={!!error}
              errorMessage={error ? error.response.city : undefined }
              completeType='locality'
              valueAs='label'
              attributes={{ id: 'city', type: 'text', required: true, defaultValue: props.jobData?.city }}
              onValueSet={onCitySelected}
            />

            {
              stateOptions &&
              <Select label='State*'
                options={stateOptions}
                forceError={!!error}
                errorMessage={error ? error.response.state : undefined }
                attributes={{ id: 'state', required: true,
                            defaultValue: stateAbbr || props.jobData?.state }}
              />
            }

            {
              (formState?.values.state === '--') &&
              <Input label='Other State (non-US state or province)*'
                forceError={!!error}
                errorMessage={error ? error.response.state_otherr : undefined }
                attributes={{ type: 'text', id: 'state_other', required: true, 
                            defaultValue: otherState || props.jobData?.state_other }} 
              />
            } 

            {
              jobDurationOptions &&
              <Select label='Select Type of Job*'
                options={jobDurationOptions}
                forceError={!!error}
                errorMessage={error ? error.response.duration : undefined }
                attributes={{ id: 'duration', required: true, defaultValue: props.jobData?.duration }}
              />
            }

            {
              jobCategoryOptions &&
              <SelectAddMulti label='Select Job Categories*'
                options={jobCategoryOptions}
                forceError={!!error}
                errorMessage={error ? error.response.categories : undefined }
                attributes={{ id: 'categories', required: true }}
                defaultValues={props.jobData?.categories}
              />
            }

            {
              formState?.values.categories?.includes('other') &&
              <Input label='Category Other*'
                forceError={!!error}
                errorMessage={error ? error.response.category_other : undefined }
                attributes={{ type: 'text', id: 'category_other', required: true, 
                            defaultValue: props.jobData?.category_other, placeholder: 'Type in Other Category' }} 
              />
            }     

            <div>
              <RadioGroup label='Interested in Help from a Recruiter?*'
                options={[
                  {label: 'Yes', value: 'true'},
                  {label: 'No', value: 'false'}
                ]}
                attributes={{ id: 'requires_recruiter', required: true, defaultValue: props.jobData?.requires_recruiter.toString() }}
              >
                <p>We&apos;ll reach out to you later to see how our snobs can help get you the best candidate!</p>
              </RadioGroup>

              <RadioGroup label='Wanna be exclusive?*'
                options={[
                  {label: 'Yes', value: 'true'},
                  {label: 'No', value: 'false'}
                ]}
                attributes={{ id: 'is_exclusive', required: true, defaultValue: props.jobData?.is_exclusive.toString() }}
              >
                <p>
                  Only check this box if you plan to list this job on Job Snob alone. If selected, this job will be given extra prominence in search results because we like being in exlusive relationships.
                  We reserve the right to remove the listing and notify you, if this post is found elsewhere, per our terms of service.
                </p>
              </RadioGroup>

              <RadioGroup label='Confidential?*'
                options={[
                  {label: 'Yes', value: 'true'},
                  {label: 'No', value: 'false'}
                ]}
                attributes={{ id: 'is_confidential', required: true, defaultValue: props.jobData?.is_confidential.toString() || 'false' }}
              >
                <p>
                  Please check &quot;Yes&quot; if this job post is confidential. 
                  If a candidate reaches out to inquire about the employer, we will not provide information if &quot;Yes&quot; is checked.
                </p>
              </RadioGroup>
            </div>
              
            {/* Description */}
            <EditorTextArea label='Job Description*' 
              forceError={!!error}
              errorMessage={error?.response.description}
              attributes={{id: 'description', required: true, defaultValue: markdown || undefined}}
            />
          </div>

          {/* Responsibilities */}
          <div className={styles.pallet}>
            <TextAreaAddMulti className={styles.textAreaContainer}
              label='Key Job Responsibilities*'
              forceError={!!error}
              errorMessage={error?.response.key_responsibilities}
              defaultValues={props.jobData?.key_responsibilities}
              attributes={{name: 'key_responsibilities', rows: 1, required: true, inputMode: 'text', maxLength: 256,
                          placeholder: 'Write responsibilities here (6 Max). Snob Tip: Keep them short and sweet!' }}
            />
          </div>

          {/* Qualifications */}
          <div className={styles.pallet}>
            <TextAreaAddMulti className={styles.textAreaContainer}
              label='Applicant Qualifications*'
              forceError={!!error}
              errorMessage={error?.response.qualifications}
              defaultValues={props.jobData?.qualifications}
              attributes={{name: 'qualifications', rows: 1, required: true, inputMode: 'text', maxLength: 256,
                          placeholder: 'Write qualifications here (6 Max).' }}
            />
          </div>
          
          {/* Compensation */}
          <div className={styles.pallet}>
            <div className={`form-sub-group form-sub-group--column ${styles.textAreaContainer}`}>
              <label>Compensation</label>
              <textarea id='compensation' name='compensation' inputMode='text' rows={1} 
                defaultValue={props.jobData?.compensation}
                placeholder='(Optional) Write compensation here.'></textarea>
              {
                error && error.response.compensation &&
                <p className='input-error'>{error.response.compensation}</p>
              }
            </div>
          </div>

          {/* Email */}
          <div className={styles.pallet}>
            <TextAreaAddMulti className={styles.textAreaContainer}
              label='Add Notification Emails (Optional)'
              forceError={!!error}
              errorMessage={error ? error?.response.additional_notification_emails : 'Email address is not valid.'}
              defaultValues={props.jobData?.additional_notification_emails}
              attributes={{name: 'additional_notification_emails', rows: 1, inputMode: 'email', maxLength: 256,
                          placeholder: 'Your account email will always receive notifications.' }}
            />
          </div>
        </form>
        
        <div className={styles.btnWrap}>
          {
            ((props.jobData?.life_cycle === 'draft' && props.mode === 'edit') || props.mode === 'new') &&
            <>
              <button type='button' form='draft' className='btn btn--white btn--border' disabled={disable} onClick={onSaveDraft}>
                Save Draft
              </button>
              <button type='submit' form='draft' className='btn' disabled={disable}>
                Submit
              </button>
            </>
          }
          {
            props.jobData?.life_cycle === 'draft' && props.mode === 'edit' &&
            <button type='button' className='btn btn--border btn--destructive-alt' 
              disabled={disable} onClick={() => setShowModal(true)}>
              Delete
            </button>
          }
          {
            ((props.jobData?.life_cycle === 'active' && props.mode === 'edit')) &&
            <button type='submit' form='draft' className='btn btn--dt-wide' disabled={disable}>Request Changes</button>
          }
        </div>
      </div>
      <Modal show={showModal} onCloseBtn={() => setShowModal(false)}>
        <p>You are about to delete a draft and this action cannot be undone.</p>
        <div className={styles.btnWrap} data-modal>
          <button type='button' className='btn btn--white btn--border btn--auto-width' onClick={() => setShowModal(false)}>Cancel</button>
          <button type='button' className='btn btn--auto-width' onClick={onDeleteDraft}>Delete</button>
        </div>
      </Modal>
    </>
  )
};

export default DraftJobPost;