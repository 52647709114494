import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../lib/app-routes';
import { useSubject } from '../../../lib/hooks';
import { navMenuShow$ } from '../../../lib/state';
import SocialMedia from '../../SocialMedia/SocialMedia';
import SVGIcon from '../../SVGIcon/SVGIcon';
import './NavMenu.scss';

const NavMenu = (): JSX.Element => {
  const show = useSubject<boolean>(navMenuShow$);

  return (
    <div className={`header-menu-wrap${show ? ' show' : ''}`}>
      <button className='close-btn' onClick={() => navMenuShow$.next(!show)}>
        <SVGIcon id='close' color='hover-black' />
      </button>

      <ul>
        <li><Link to={ROUTES.jobboard.path} aria-current="page">Job Board</Link></li>
        <li><Link to={ROUTES.purchase.path} aria-current="page">Employers</Link></li>
        <li><a href="https://info.jobsnob.net/acquisitions" target="_self">Acquisitions</a></li>
        {/* <li><a href="https://info.jobsnob.net/aesthetic-data" target="_self">Aesthetic Data</a></li> */}
        <li><a href="https://info.jobsnob.net/resources" target="_self">Resources</a></li>
        <li><a href="https://info.jobsnob.net/blog" target="_self">Blog</a></li>
        <li><a href="https://info.jobsnob.net/about-us" target="_self">About Us</a></li>
      </ul>

      <SocialMedia fbColor='black' instaColor='hover-black' inColor='black' size={24} />
    </div>
  )
};

export default NavMenu;