import React, { FormEvent, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import EmailSent from '../../../components/EmailSent/EmailSent';
import Input from '../../../components/Input';
import SoloNLIForm from '../../../components/SoloNLIForm/SoloNLIForm';
import { DEFAULT_FORM_VALIDITY_STATE } from '../../../lib/constants';
import { ROUTES } from '../../../lib/app-routes';
import { ErrorResponse, FormValidityState } from '../../../lib/types';
import { getDeepClone } from '../../../lib/util.functions';
import { emailValidator } from '../../../lib/validator.functions';
import styles from './ForgotPassword.module.scss';
import UserService from '../../../lib/services/user.service';

const ForgotPassword = (): JSX.Element => {
  const [formState, setFormState] = useState<FormValidityState>(getDeepClone(DEFAULT_FORM_VALIDITY_STATE));
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<ErrorResponse | null>(null);

  const onSubmit = (e: FormEvent): void => {
    e.preventDefault();
    if (error) setError(null);
    UserService.resetPassword({ ...formState.values as unknown as {email: string} }).subscribe({
      next: () => setSuccess(true),
      error: (e) => setError(e)
    })
  };
  
  return (
    <>
      <Helmet>
        <title>Job Snob: Forgot Password</title>
      </Helmet>
      {
        success
        ?
        <EmailSent eyebrow='forgot password' notReceivedFn={() => setSuccess(false)}/>
        :
        <SoloNLIForm
          title='Forgot Password?'
          eyebrow='Don’t worry, it happens'
          contentWrapClass='bg1-light'
          onSubmit={onSubmit}
          setter={setFormState}
          defaultState={formState}
        >
          <Input
            className={error ? styles.inputError : ''}
            forceError={!!error}
            errorMessage={error? error?.response.new_password : 'email address is not valid'}
            icon={error ? 'error' : undefined}
            iconColor='red'
            validatorFns={[emailValidator]}
            attributes={{type: 'email', id: 'email', placeholder: 'Type your email address here', required: true}} 
          />

          <button className='btn' type='submit'>Reset password</button>
          
          <div className={`link-wrap ${styles.linkWrap}`}>
            <Link to={ROUTES.login.path}>
              back to login?
            </Link>
          </div>
        </SoloNLIForm>
      }
    </>
  )
};

export default ForgotPassword;